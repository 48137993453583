/* eslint-disable react/forbid-prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb as BootstrapCrumbs } from 'react-bootstrap';
import styled from 'styled-components';
import YEP, { server } from '../../api';
import { LanguageContext } from '../../contexts';
import { translate } from '../../resources/translations';
import variables from '../../styles/variables.scss';
import Chip from "@material-ui/core/Chip";
import { Breadcrumbs, Typography, Link } from '@material-ui/core'


const Section = styled.div`
  text-align: right;
  text-align: justify;
  padding: 10px 20px 10px 20px;
`;

const Tag = styled.span`
  padding: 2px 7px 2px 7px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${variables.disabled};
  color: black;
  margin: 5px;
  transition: 0.3s;
  border-width: 1px;
  border-style: solid;
  border-color: ${variables.disabled};
`;



const Details = ({ ...props }) => {
  const { language } = useContext(LanguageContext);
  const [job, setJobDetail] = useState({});
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  const getJobDetail = async () => {
    let thePath = window.location.href;
    let id = thePath.substring(thePath.lastIndexOf('/') + 1)
    const { data: _job } = await YEP.website.jobs.getJob(id);
    setJobDetail(_job);

  }

  useEffect(() => {
    getJobDetail();
  }, []);
  return (
    <div className="container mb-3">
      <div className="row">
        <div className="col-12">
          <BootstrapCrumbs style={{ marginTop: "1.5rem" }} dir={dir} className="bg-transparent">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="#/">
              {translate('home')}
              </Link>
              <Link color="inherit" href="#/jobs">
              {translate('jobs')}
              </Link>
              { job.title && <Typography color="textPrimary">{job.title}</Typography>}
            </Breadcrumbs>
          </BootstrapCrumbs>
        </div>
      </div>
      <div dir={dir} className="row mx-auto">
        <Section className="col-md-12 col-12 rounded">
          <div className="d-flex flex-row align-items-center">
            <h3>{job.title}</h3>
            <Tag className="d-inline-block text-light">{job.job_type?.name}</Tag>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="d-flex flex-row align-items-center">
                <i className="far fa-clock" />
                <p className="p-0 m-0 mx-2">{moment(job.created_at).startOf('day').fromNow()}</p>
              </div>
              <div className="mt-4">
                <h6>{translate('qualifications')}</h6>
                <hr className="my-1" />
                {/* <div>{job.qualifications}</div> */}
                <div dangerouslySetInnerHTML={{ __html: job.qualifications }} />
              </div>
              <div className="mt-4">
                <h6>{translate('job_requirements')}</h6>
                <hr className="my-1" />
                {/* <p>{job.job_requirements}</p> */}
                <div dangerouslySetInnerHTML={{ __html: job.job_requirements }} />
              </div>

              {job.tips &&
                <div className="mt-4">
                  <h6>{translate('tips')}</h6>
                  <hr className="my-1" />
                  {/* <pre>{job.tips}</pre> */}
                  <div dangerouslySetInnerHTML={{ __html: job.tips }} />

                </div>
              }
              {
                job.keywords &&
                <div className="mt-4">
                  <h6>{translate('key_words')}</h6>
                  <hr />


                  {
                    job.keywords.map((keyword) => (
                      <Chip
                        label={keyword}
                        style={{ margin: '5px' }}

                      />

                      // <p>{keyword}</p>
                    ))
                  }


                  <div>

                    {/* <pre>{job.keywords}</pre> */}

                    { }
                  </div>
                </div>}
            </div>
            <div className="col-md-4 col-12 mt-3">
              <Section >
                <h3>{translate('company_info')}</h3>
                <img
                  width={248}
                  src={`${server}/${job.company?.logo}`}
                  alt="job logo"
                  className="bg-white"
                />
                <h2 className="text-center mt-2">{job.company?.name}</h2>
                {job.company?.email && (
                  <div className="text-center mt-5">
                    <a
                      role="button"
                      variant="primary"
                      className="btn btn-primary col-12"
                      href={`mailto:${job.company.email}`}
                    >
                      <i className="fas fa-envelope text-light mx-2" />
                      {translate('send_email')}
                    </a>
                  </div>
                )}
              </Section>
              <Section>
                <div>
                  <small className="font-weight-bold">{translate('start_date')} :</small>
                  <small> {moment(job.start_date).format('ll')}</small>
                </div>
                <div>
                  <small className="font-weight-bold">{translate('company')} :</small>
                  <small> {job.company?.name}</small>
                </div>
                <div>
                  <small className="font-weight-bold">{translate('salary')} :</small>
                  <small> {job.salary}</small>
                </div>
                <div>
                  <small className="font-weight-bold">{translate('job_type')} :</small>
                  <small> {job.job_type?.name}</small>
                </div>
                <div>
                  <small className="font-weight-bold">{translate('location')} :</small>
                  <small> {job.city?.name}</small>
                </div>
              </Section>
              {/* <Section className="m-0 p-0 mt-5">
                <div>
                  <a href="/">
                    <i className="fas fa-heart text-primary mx-2" />
                    <small className="d-inline-block text-primary">{translate('save_job')}</small>
                  </a>
                  <hr />
                  <a href="/">
                    <i className="fas fa-info-circle text-primary mx-2" />
                    <small className="d-inline-block text-primary">
                      {translate('report_misuse')}
                    </small>
                  </a>
                </div>
              </Section> */}
            </div>
          </div>
          <hr />
          {job.company?.email && (
            <div>
              <a
                role="button"
                variant="primary"
                className="btn btn-primary col-12"
                href={`mailto:${job.company.email}`}
              >
                <i className="fas fa-envelope text-light mx-2" />
                {translate('send_email')}
              </a>
            </div>
          )}
        </Section>


      </div>
    </div>
  );
};

export default Details;

Details.propTypes = {
  location: PropTypes.object.isRequired,
};
