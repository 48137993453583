import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import qs from 'query-string';

export const api = '/api/v1';
export const server = 'https://yep-sy.com';
// export const server = 'http://127.0.0.1:8000';

const YEP = axios.create({
  baseURL: server,
  timeout: 20000,
});

const mock = new MockAdapter(axios);

mock.onGet(`${server}${api}/website/blog/slider`).reply(200, {
  data: [
    {
      deleted_at: null,
      id: 1,
      title: '<i class="fas fa-book-open"></i> المدونة',
      rank: null,
      image: '/images/slider_1.png',
      created_at: null,
      updated_at: null,
    },
    {
      deleted_at: null,
      id: 1,
      title: '<i class="fas fa-book-open"></i> المدونة',
      rank: null,
      image: '/images/slider_2.png',
      created_at: null,
      updated_at: null,
    },
  ],
});

mock.onGet(`${server}${api}/website/events/slider`).reply(200, {
  data: [
    {
      deleted_at: null,
      id: 1,
      title: '<i class="fas fa-book-open"></i> الفعاليات',
      rank: null,
      image: '/images/slider_1.png',
      created_at: null,
      updated_at: null,
    },
    {
      deleted_at: null,
      id: 1,
      title: '<i class="fas fa-book-open"></i> الفعاليات',
      rank: null,
      image: '/images/slider_3.png',
      created_at: null,
      updated_at: null,
    },
  ],
});

mock.onGet(`${server}${api}/website/media/slider`).reply(200, {
  data: [
    {
      deleted_at: null,
      id: 1,
      title: '<i class="fas fa-book-open"></i> الإعلام',
      rank: null,
      image: '/images/slider_1.png',
      created_at: null,
      updated_at: null,
    },
    {
      deleted_at: null,
      id: 1,
      title: '<i class="fas fa-book-open"></i> الإعلام',
      rank: null,
      image: '/images/slider_5.png',
      created_at: null,
      updated_at: null,
    },
  ],
});

const defaultAPI = {
  website: {
    post: async (id) => {
      try {
        const { data } = await YEP.get(`${api}/website/post/${id}`);
        return data.data[0];
      } catch (err) {
        return Promise.reject(err);
      }
    },
    subscribeEmail: async ({ name, email }) => {
      try {
        const { data } = await YEP.post(`${api}/website/subscription`, { name, email });
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    stats: async () => {
      try {
        const { data } = await YEP.get(`${api}/website/numbers`);
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    slider: async () => {
      try {
        const { data } = await YEP.get(`${api}/website/slider`);
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    news: async () => {
      try {
        const { data } = await YEP.get(`${api}/website/latest-news`);
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    promo: async () => {
      try {
        const { data } = await YEP.get(`${api}/website/intro`);
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    cities: async () => {
      try {
        const { data } = await YEP.get(`${api}/website/city`);
        return data.data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    provinces: async () => {
      try {
        const { data } = await YEP.get(`${api}/website/branch-info`);
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    branch: async (id) => {
      const { data } = await YEP.get(`${api}/website/branch-info/${id}`);
      return data.data;
    },
    contactus: async (input) => {
      try {
        const { data } = await YEP.post(`${api}/website/contact-us`, input);
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    events: {
      slider: async () => {
        try {
          const { data } = await axios.get(`${server}${api}/website/events/slider`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
      articles: async () => {
        try {
          const { data } = await YEP.get(`${server}${api}/website/post?type=event`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
    },
    blog: {
      slider: async () => {
        try {
          const { data } = await axios.get(`${server}${api}/website/blog/slider`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
      articles: async () => {
        try {
          const { data } = await YEP.get(`${server}${api}/website/post?type=blog`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
      getArticle: async (id) => {
        try {
          const { data } = await axios.get(`${server}${api}/website/blog/article/${id}`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
    },
    media: {
      slider: async () => {
        try {
          const { data } = await axios.get(`${server}${api}/website/media/slider`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
      articles: async () => {
        try {
          const { data } = await YEP.get(`${server}${api}/website/post?type=media`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
      getArticle: async (id) => {
        try {
          const { data } = await axios.get(`${server}${api}/website/blog/article/${id}`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
    },
    jobs: {
      all: async (page = 1, filter = null) => {
        try {
          const filterString = filter ? qs.stringify(filter, { arrayFormat: 'index' }) : '';
          const { data } = await YEP.get(
            `${server}${api}/website/vacancy?page=${page}&${filterString}`
          );
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
      filters: async () => {
        try {
          const { data } = await YEP.get(`${server}${api}/website/vacancy-filters`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
      getJob: async (id) => {
        try {
          const { data } = await YEP.get(`${server}${api}/website/vacancy/${id}`);
          return data;
        } catch (err) {
          return Promise.reject(err);
        }
      },
    },
  },
};
export default defaultAPI;
