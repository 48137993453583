export default {
  en: {
    home_header_title: 'Youth Empowerment Project',
    home: 'Home',
    jobs: 'Jobs',
    events: 'Events',
    stats: 'Stats',
    about: 'About',
    media: 'Media',
    blog: 'Blog',
    new_in_yep: 'YEP News',
    guidance_message: 'Career coaching and counseling',
    enterpreneurship_message: 'Entrepreneurship support',
    skills_message: 'Professional and vocational skills courses',
    skills_message1: 'Professional and vocational',
    skills_message2: 'skills courses',
    education_message: 'Continuing Education',
    aleppo: 'Aleppo',
    damascus: 'Damascus',
    homs: 'Homs',
    hama: 'Hama',
    lattakia: 'Lattakia',
    deir_ezzor: 'Deir Ezzor',
    rakka: 'Rakka',
    qamishli: 'Qamishli',
    idlib: 'Idlib',
    daraa: 'Daraa',
    tartous: 'Tartous',
    choose_city: 'Choose province or city',
    more_cities: 'More cities >>',
    explore_activities: 'Explore activities',
    contact_us: 'Contact us',
    our_offices: 'Our offices',
    email: 'Email',
    full_name: 'Full name',
    subscribe: 'Subscribe',
    subscribe_message: 'Subscribe to our mail',
    blog_header: 'Welcome to YEP Blog',
    blog_header_title_2: 'We regularly update our blog',
    blog_header_title_3: 'Here you can find new articles related to YEP projects',
    read_more: 'Read more',
    share_this_article: 'Share this article on social media',
    company_name: 'Company name',
    job_type: 'Job type',
    work_location: 'Work location',
    search: 'Search',
    arrange_by: 'Arrange By',
    found: 'Found',
    job: 'job',
    save_job: 'Save job',
    job_email: 'Job email address',
    events_header: 'Events',
    ad_date: 'Ad Date',
    filter_time_1: '24 Hours',
    filter_time_2: '3 Days',
    filter_time_3: '7 Days',
    filter_time_4: '30 Days',
    job_type_1: 'Full time',
    job_type_2: 'Part time',
    job_type_3: 'Temporary',
    job_type_4: 'Contract',
    job_type_5: 'Internship',
    job_type_6: 'Permenarnt',
    from: 'From',
    to: 'To',
    location: 'Location',
    looking_for_job: 'Looking for a job?',
    download_our_app: 'Download our application now',
    qualifications: 'Qualifications',
    key_words: 'Key words',
    start_date: 'Start Date',
    company: 'Company',
    salary: 'Salary',
    report_misuse: 'Report',
    send_email: 'Send email',
    company_info: 'Company Info',
    job_category: 'Job Category',
    no_results: 'No Results',
    job_requirements: 'Job requirements',
    tips: 'Tips',
    mobile_number: 'Mobile number',
    message: 'Message',
    media_tag: 'Media',
    vacancy_tag: 'Vacancy',
    event_tag: 'Event',
    blog_tag: 'Blog',
    city_1: 'Idlb',
    city_2: 'Al-Hasakah',
    city_3: 'Aleppo',
    city_4: 'Hama',
    city_5: 'Homs',
    city_6: 'Deir ez-Zor',
    city_7: 'Damascus',
    city_8: 'Daraa',
    city_9: 'Al Raqqah',
    city_10: 'Rif-Dimashq',
    city_11: 'As-Suwayda',
    city_12: 'Tartus',
    city_13: 'Al Qunaitra',
    city_14: 'Lattakia',
    submit: "Submit",
    yep1 : "Entrepreneurship workshops <br/> participants",
    yep2 : "Beneficiaries of Employment <br/> Support Services",
    yep3 : "Beneficiaries of vocational <br/> training and continuing support",
    yep4 : "Supported <br/> startups",
  },
  ar: {
    home_header_title: 'مشروع تمكين الشباب',
    home: 'الرئيسية',
    jobs: 'فرص عمل',
    events: 'ورشات عمل',
    stats: 'موارد و احصائيات',
    about: 'عن المشروع',
    media: 'دورات',
    blog: 'الأخبار',
    new_in_yep: 'جديد المشروع',
    guidance_message: 'الإرشاد والتوجيه الوظيفي',
    enterpreneurship_message: 'دعم ريادة الأعمال',
    skills_message: 'التدريب المهني',
    skills_message1: 'التدريب المهني',
    skills_message2: '',
    education_message: 'التعليم المستمر',
    aleppo: 'حلب',
    damascus: 'دمشق',
    homs: 'حمص',
    hama: 'حماة',
    lattakia: 'اللاذقية',
    deir_ezzor: 'دير الزور',
    rakka: 'الرقة',
    qamishli: 'القامشلي',
    idlib: 'ادلب',
    daraa: 'درعا',
    tartous: 'طرطوس',
    choose_city: 'اختر مدينة أو منطقة',
    more_cities: 'المزيد من المدن >>',
    explore_activities: 'اكتشف النشاطات',
    contact_us: 'اتصل بنا',
    our_offices: 'مكاتبنا',
    email: 'البريد الالكتروني',
    full_name: 'الاسم الكامل',
    subscribe: 'اشترك',
    subscribe_message: 'اشترك ببريدنا',
    blog_header: 'أهلاً و سهلاً مدونة مشروع تمكين الشباب',
    blog_header_title_2: 'نقوم بتحديث مدونتنا بشكل دائم',
    blog_header_title_3: 'يمكنك الاطلاع هنا على كافة المستجدات الحاصة بمشروع تمكين الشباب',
    read_more: 'قراءة المزيد',
    share_this_article: 'شارك المقال على وسائل التواصل الإجتماعي',
    company_name: 'اسم الشركة',
    job_type: 'طبيعة العمل',
    work_location: 'مكان العمل',
    search: 'ابحث',
    arrange_by: 'ترتيب حسب',
    found: 'تم العثور على',
    job: 'وظيفة',
    save_job: 'حفظ الوظيفة',
    job_email: 'البريد الالكتروني للوظيفة',
    events_header: 'ورشات العمل',
    ad_date: 'تاريخ الإعلان',
    filter_time_1: '24 ساعة',
    filter_time_2: '3 أيام',
    filter_time_3: '7 أيام',
    filter_time_4: '30 يوم',
    job_type_1: 'دوام كامل',
    job_type_2: 'دوام جزئي',
    job_type_3: 'مؤقت',
    job_type_4: 'عقد',
    job_type_5: 'تدريب',
    job_type_6: 'دائم',
    from: 'من',
    to: 'إلى',
    location: 'موقعك',
    looking_for_job: 'تبحث عن وظيفة؟',
    download_our_app: 'حمل تطبيقنا الان',
    qualifications: 'المؤهلات',
    key_words: 'الكلمات المفتاحية',
    start_date: 'تاريخ البدء',
    company: 'الشركة',
    salary: 'الراتب',
    report_misuse: 'بلغ عن سوء معاملة',
    send_email: 'إرسال بريد الكتروني',
    company_info: 'معلومات الشركة',
    job_category: 'تصنيف الوظيفة',
    no_results: 'لا يوجد نتائج',
    job_requirements: 'متطلبات الوظيفة',
    tips: 'نصائح للمرشحين',
    mobile_number: 'رقم الموبايل',
    message: 'الرسالة',
    media_tag: 'اعلام',
    vacancy_tag: 'فرصة عمل',
    event_tag: 'فعالية',
    blog_tag: 'مدونة',
    city_1: 'إدلب',
    city_2: 'الحسكة',
    city_3: 'حلب',
    city_4: 'حماة',
    city_5: 'حمص',
    city_6: 'دير الزور',
    city_7: 'دمشق',
    city_8: 'درعا',
    city_9: 'الرقة',
    city_10: 'ريف دمشق',
    city_11: 'السويداء',
    city_12: 'طرطوس',
    city_13: 'القنيطرة',
    city_14: 'اللاذقية',
    submit: "ارسال",
    yep1 : "المستفيدين من ورشات<br/> ريادة الأعمال",
    yep2 : "المستفيدين من خدمات<br/> دعم التوظيف",
    yep3 : "المستفيدين من التدريب المهني<br/> والدعم المستمر",
    yep4 : "الشركات الناشئة<br/> التي تم دعمها",
  },
};
