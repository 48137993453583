import React, { useContext } from 'react';
import styled from 'styled-components';
import { translate } from '../../resources/translations';
import { LanguageContext } from '../../contexts';

const Container = styled.div`
  width: 100%;
  border-top-width: 1px;
  border-top-color: #e3e3e3;
  border-top-style: solid;
  text-align : center;
`;
const List = styled.ul`
  list-style-type: none;
`;

const Footer = () => {
  const { language } = useContext(LanguageContext);
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <Container className="py-5 bg-section">
      <div className="container">
        <div className="row">
          <span className="col-md">
            <img alt="footer-logos" src="/assets/imgs/Mosal.png" width={118} />
            <img alt="footer-logos" src="/assets/imgs/UNDP.png" width={64} />
          </span>
          <div dir={dir} className="mb-auto mt-4 col-md">
            <h6>{translate('contact_us')}</h6>
            <List>
              <li>
                <a href="mailto:yep@undp.sy">Email: yep@undp.sy</a>
              </li>
              <li>
                <a href="tel:+9639123456789">Phone: +963-123456789</a>
              </li>
            </List>
          </div>
          <div dir={dir} className="mb-auto mt-4 col-md">
            <h6>{translate('our_offices')}</h6>
            <List>
              <li>
                <a href="/#/branch/3">Damascus-Mazzeh</a>
              </li>
            </List>
          </div>
          <span className="col-md">
          <img alt="logo" src="/logo.svg" width={150} />
          </span>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
